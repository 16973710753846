.bestOrthopaedicsurgeoninKrishnanagar{
    background-color: #28a1ca;
    padding: 10px;
}

.colorbacks{
    background-color: #C6E2FA; /* Green */
    border: none;
    color: #136293;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
}


.blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }

  .callbook{
    background-color: #0f0fc2; /* Green */
    border: none;
    color: white;
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 3px;
  }

  .callbooks{
    background-color: #0f0fc2; /* Green */
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 10px;
  }

  .button1 {
    background-color: white; 
    color: black; 
    border: 2px solid #0f0fc2;
  }
  
  .button1:hover {
    background-color: #0f0fc2;
    color: white;
  }

  

  * {box-sizing: border-box}

  /* Container needed to position the overlay. Adjust the width as needed */
  .hoverimage {
    position: relative;
  
  }
  
  /* Make the image to responsive */
  .image1 {
    display: block;
    width: 100%;
    height: auto;
  }
  
  /* The overlay effect - lays on top of the container and over the image */
  .overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 20px;
    text-align: center;
  }
  
  /* When you mouse over the container, fade in the overlay title */
  .hoverimage:hover .overlay {
    opacity: 1;
  }