.spinesurgerykrishnanagar{
        background-color: rgb(168, 214, 254);
        padding: 24px;
}

.no1droctorkrishnagar img{
    border-radius: 18px;
}

* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.hoverimage1 {
  position: relative;

}

/* Make the image to responsive */
.image2 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(162, 194, 240);
  background: rgba(120, 191, 224, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 14px;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimage1:hover .overlay1 {
  opacity: 1;
}